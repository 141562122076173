import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutSurpriseCustom"
import BgImg from "../../../images/NowWhatBig.jpg"
import NowWhat from "../../../images/nowWhatSeries.jpg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Now What? Series"
    SecondText="Check out sermons from our Now What? Series."
  >
    <SEO title="Sermons - Now What?" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <SermonBox
        imageUrl={NowWhat}
        imageAlt="Now What? Sermon Series"
        sermonTitle="Malachi"
        sermonSeries="Now What?"
        sermonLink="/surprise/sermons/malachi"
        date="September 6, 2020"
        pastor="Pastor TC"
        badge="NEW"
        badgeColor="teal"
      />
      <SermonBox
        imageUrl={NowWhat}
        imageAlt="Now What? Sermon Series"
        sermonTitle="Micah"
        sermonSeries="Now What?"
        sermonLink="/surprise/sermons/micah"
        date="August 30, 2020"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={NowWhat}
        imageAlt="Now What? Sermon Series"
        sermonTitle="Amos"
        sermonSeries="Now What?"
        sermonLink="/surprise/sermons/amos"
        date="August 23, 2020"
        pastor="Pastor Chad"
      />
      <SermonBox
        imageUrl={NowWhat}
        imageAlt="Now What? Sermon Series"
        sermonTitle="Joel"
        sermonSeries="Now What?"
        sermonLink="/surprise/sermons/joel"
        date="August 16, 2020"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={NowWhat}
        imageAlt="Now What? Sermon Series"
        sermonTitle="Hosea"
        sermonSeries="Now What?"
        sermonLink="/surprise/sermons/hosea"
        date="August 9, 2020"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={NowWhat}
        imageAlt="Now What? Sermon Series"
        sermonTitle="Ezekiel"
        sermonSeries="Now What?"
        sermonLink="/surprise/sermons/ezekiel"
        date="August 2, 2020"
        pastor="Pastor TC"
      />
      <SermonBox
        imageUrl={NowWhat}
        imageAlt="Now What? Sermon Series"
        sermonTitle="Jeremiah"
        sermonSeries="Now What?"
        sermonLink="/surprise/sermons/jeremiah"
        date="July 26, 2020"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={NowWhat}
        imageAlt="Now What? Sermon Series"
        sermonTitle="Isaiah"
        sermonSeries="Now What?"
        sermonLink="/surprise/sermons/isaiah"
        date="July 19, 2020"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/surprise/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Surprise"
      />
    </Grid>
  </Layout>
)

export default IndexPage
